import './App.css';
import { Footer, Header } from './layouts';
import Navbar from './layouts/Navbar';
import routes from './routes/routes';
import FormProvider from './store/FormProvider';


function App() {

  return (
    <FormProvider>
      <div className="App">

        <Navbar />

        <Header />

        {routes()}

        <Footer />

      </div>
    </FormProvider>
  );
}

export default App;
