import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const icons = [
    faFacebookF,
    faTwitter,
    faWhatsapp,
    faYoutube,
    faInstagram,
    faLinkedinIn
];

const renderIcons = icons.map((item, index) => {
    return (
        <Link to={''} key={index} className="w-[40px] h-[40px] me-3 flex items-center justify-center bg-[var(--light-red-50)] rounded-full">

            <FontAwesomeIcon icon={item} className="text-[20px]" />

        </Link>
    )
})

export default function FooterLinks() {

    return (
        <div className="flex-1">

            <h1 className="text-[20px] text-[#130707] font-medium mb-3">Follow us</h1>

            <div className="flex items-center mt-3 h-[96px]">

                {renderIcons}

            </div>

        </div>
    )
}
