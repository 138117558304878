import React, { useState } from 'react';
import FormContext from './form-context';

const FormProvider = ({ children }) => {
    const [birthday, setBirthday] = useState();
    const [endDate, setEndDate] = useState();
    const [duration, setDuration] = useState('1');
    const [selectedCompany, setSelectedCompany] = useState();
    const [user, setUser] = useState({ gender: 'E' });
    const [personId, setPersonId] = useState();
    const [policeId, setPoliceId] = useState();
    const [cardType, setCardType] = useState();
    const [price, setPrice] = useState();

    const formatDate = (inputDate) => {
        const date = new Date(inputDate).toLocaleDateString('en-GB');
        let dateComponents = date.split('/');
        let formattedDate =
            dateComponents[0] + '.' + dateComponents[1] + '.' + dateComponents[2];
        return formattedDate;
    };

    const [startDate, setStartDate] = useState();

    const setBirthdayFn = (inputDate) => {
        setBirthday(formatDate(inputDate));
    };
    const setStartDateFn = (inputDate) => {
        setStartDate(formatDate(inputDate));
        if (startDate) {
            setEndDateFn(startDate);
        }
    };

    const setDurationFn = (duration) => {
        setDuration(duration);
    };

    const setPersonIdFn = (id) => {
        setPersonId(id);
    };
    const setPoliceIdFn = (id) => {
        setPoliceId(id);
    };
    const setCardTypeFn = (type) => {
        setCardType(type);
    };
    const setPriceFn = (price) => {
        setPrice(price);
    };

    const setEndDateFn = (startDate) => {
        let parts = startDate.split('.');
        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10) - 1; // Months are zero-based
        let year = parseInt(parts[2], 10);
        // console.log(`${day}/${month + 1}/${year + parseInt(duration)}`);
        // let originalUTCDate = Date.UTC(year + parseInt(duration), month, day);

        // let resultDate = new Date(originalUTCDate).toLocaleDateString();
        // console.log(resultDate);
        setEndDate(`${day}.${month + 1}.${year + parseInt(duration)}`);
    };
    const setSelectedCompanyFn = (company) => {
        setSelectedCompany(company);
    };
    const setUserFn = (user) => {
        setUser(user);
    };
    const value = {
        birthday,
        startDate,
        endDate,
        duration,
        selectedCompany,
        user,
        personId,
        policeId,
        cardType,
        price,
        setBirthday: setBirthdayFn,
        setStartDate: setStartDateFn,
        setDuration: setDurationFn,
        setEndDate: setEndDateFn,
        setSelectedCompany: setSelectedCompanyFn,
        setUser: setUserFn,
        setPersonId: setPersonIdFn,
        setPoliceId: setPoliceIdFn,
        setCardType: setCardTypeFn,
        setPrice: setPriceFn,
    };
    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export default FormProvider;
