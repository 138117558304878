import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { RelatedLinks } from '../../components'
import { socialLinks } from './data'
import menu from '../../assets/icons/menu.svg'
export default function Header() {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <header className='px-[10px] md:px-[40px] lg:px-[60px] h-[72px] shadow-lg flex items-center justify-between relative'>


            <h1 className='text-[var(--light-red)] font-bold text-[20px]'><Link to="/">ikamet Sigorta</Link></h1>

            <ul className='flex items-center max-sm:hidden'>

                <NavLink to={''} className={'font-medium me-5 text-[#8B6464] p-2 rounded-[5px]'}>

                    Home

                </NavLink>

                <NavLink to={'/faq'} className={'font-medium me-5 text-[#8B6464] p-2 rounded-[5px]'}>

                    FAQ?

                </NavLink>

            </ul>

            <div className='flex items-center  max-sm:hidden'>

                <RelatedLinks list={socialLinks} />

            </div>
            <img src={menu} alt='menu' className='cursor-pointer sm:hidden' onClick={() => {
                setShowMenu(prev => !prev)
            }} />
            {showMenu && <div className='sm:hidden absolute top-[72px] left-0 w-screen z-[10000] bg-white flex flex-col items-center p-4'>
                <NavLink to={'/faq'} className={'font-medium me-5 text-[#8B6464] p-2 rounded-[5px]'}>

                    FAQ?

                </NavLink>
                <div className='flex gap-4 flex-wrap'>
                    <RelatedLinks list={socialLinks} />
                </div>

            </div>
            }
        </header>
    )
}
