// importing require modules
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(_ => import('../pages/Home'));
const Profile = lazy(_ => import('../pages/Profile'));
const Insurance = lazy(_ => import('../pages/Insurance'));
const FAQ = lazy(_ => import('../pages/FAQ'));
const Payment = lazy(_ => import('../pages/Payment'));
const Processing = lazy(_ => import('../pages/Processing'));

export default function routes() {
    return (

        <Suspense >

            {/* Route configuration */}
            <Routes>

                {/* Route for homepage */}
                <Route path="/" element={<Home />} />

                <Route path="/insurance" element={<Insurance />} />

                <Route path="/profile" element={<Profile />} />

                <Route path="/payment" element={<Payment />} />

                <Route path="/processing" element={<Processing />} />

                <Route path="/faq" element={<FAQ />} />

            </Routes>

        </Suspense>
    );
}
