import { Link } from "react-router-dom"

// this component render and darw group of  links to set it to ui
export default function RelatedLinks({ list, target }) {
    return (

        list.map((link, index) => {

            return (
                link.type === "anchor" ?

                    <a key={index + 1} href={link.href} className={link.classes} target={target}>

                        {link.text}

                    </a>
                    :
                    <Link key={index + 1} to={link.href} className={link.classes} target={target}>

                        {link.text}

                    </Link>
            );

        })

    )
}
