// Import the necessary FontAwesome icons and components
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Navbar() {

    // Render the navbar
    return (
        // Render a nav element with class 'flex bg-[var(--primary-color)] h-[48px] px-[10px] md:px-[40px] lg:px-[60px]'
        <nav className="flex h-[48px] px-[10px] md:px-[40px] lg:px-[60px] bg-[var(--primary)] relative">

            {/* Render a div with class 'w-full flex justify-between items-center' */}
            <div className="w-full flex justify-between items-center ">
                {/* Render a div with class 'flex items-center' */}
                <div className="flex items-center">

                    <h1 className="text-white font-medium">Health Insurance - Its All</h1>

                </div>

                {/* Render a paragraph with class 'me-4 text-[13px] lg:text-[16px]' */}
                <p className="me-4 text-[13px] text-white lg:text-[16px]">

                    {/* Render the faLocationDot icon */}
                    <FontAwesomeIcon icon={faPhone} className='me-4'></FontAwesomeIcon>

                    {/* Render the Phone */}
                    605-646-9241
                </p>

            </div>

        </nav>
    )
}
