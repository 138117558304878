import React from 'react'
import ContactInfoBox from '../ContactInfoBox';

export default function FooterContent() {

    return (

        <div className="flex-1" style={{ textTransform: "initial" }}>

            <h1 className='mb-3 text-[23px] font-medium text-[var(--light-red)]'>ikamet Sigorta</h1>

            <div className='flex max-sm:flex-col sm:items-center'>

                <ContactInfoBox />

            </div>

        </div>
    )
}
