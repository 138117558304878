import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const contactData = [
    { heading: "Have a question?", icone: faPhoneVolume, text: "+34611251302", classes: "flex items-center justify-between mt-3 me-5 mb-3 sm:mb-0" },
]

const renderContactData = contactData.map((ele, index) => {

    return (
        // Render a div with the classes specified in the element and a unique key based on its index
        <div className={ele.classes} key={index}>

            {/* Render a div with a fixed size and a rounded shape, with an icon and custom styling */}
            <div className="w-[52px] h-[52px] rounded-full flex items-center justify-center bg-[var(--light-red-50)] me-2">

                <FontAwesomeIcon icon={ele.icone} className='text-[var(--light-red)]'></FontAwesomeIcon>

            </div>

            {/* Render a heading and a paragraph with custom styles and content */}
            <div>

                <h6 className="text-[var(--text-color)]">{ele.heading}</h6>

                <p className="text-[var(--light-red)] font-[600]">{ele.text}</p>

            </div>

        </div>
    )
})
// This Component Will Render The Contact Box 
export default function ContactInfoBox() {

    return renderContactData;

}
