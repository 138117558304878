import { createContext } from 'react';

const FormContext = createContext({
    birthday: null,
    startDate: null,
    endDate: null,
    duration: '1',
    selectedCompany: {},
    user: {},
    personId: null,
    policeId: null,
    cardType: null,
    price: null,
    setBirthday: () => { },
    setStartDate: () => { },
    setEndDate: () => { },
    setDuration: () => { },
    setSelectedCompany: () => { },
    setPersonId: () => { },
    setPoliceId: () => { },
    setCardType: () => { },
    setPrice: () => { }
});

export default FormContext;
