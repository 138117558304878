import FooterContent from './FooterContent/FooterContent'
import FooterLinks from './FooterLinks/FooterLinks';


export default function Footer() {
    return (

        <footer className='p-3 py-10' style={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>

            <div className="container p-5">

                <div className="flex flex-col gap-5">

                    <div className='flex max-sm:flex-col justify-between'>
                        <FooterContent />
                        <FooterLinks />
                    </div>

                    <div className=' mt-2 flex items-center justify-between'>

                        <h6 className='text-[var(--text-color)]'>Privacy policy</h6>

                        <p className='text-[var(--text-color)]'>


                            © 2023, All Rights Reserved to

                            <a href='https://softa-solutions.com/' className='ms-2 text-[var(--light-red)] font-bold'>Softa Solutions</a>

                        </p>

                    </div>

                </div>

            </div>

        </footer>
    )
}
